<template>
  <div class="flex flex-center full-height bg-white">
    <swiper
      :modules="[Autoplay]"
      :pagination="{ type: 'fraction' }"
      :autoplay="{ delay: 3500, disableOnInteraction: true, pauseOnMouseEnter: true }"
      :style="{ '--swiper-pagination-fraction-color': '#000' }"
      :loop="items.length > 1"
      class="col"
      @swiper="(swiper) => carousel = swiper"
      @active-index-change="(swiper) => index = swiper.realIndex"
    >
      <!-- eslint-disable @stylistic/max-len -->
      <div
        v-if="items.length > 1"
        class="q-carousel__control q-carousel__arrow q-carousel__prev-arrow q-carousel__prev-arrow--horizontal absolute flex flex-center z-top gt-sm"
      >
        <q-btn
          :icon="mdiChevronLeft"
          color="primary"
          flat
          round
          dense
          @click="carousel?.slidePrev()"
        />
      </div>
      <div
        v-if="items.length > 1"
        class="q-carousel__control q-carousel__arrow q-carousel__next-arrow q-carousel__next-arrow--horizontal absolute flex flex-center z-top gt-sm"
      >
        <q-btn
          :icon="mdiChevronRight"
          color="primary"
          flat
          round
          dense
          @click="carousel?.slideNext()"
        />
      </div>
      <!-- eslint-enable @stylistic/max-len -->
      <div
        v-if="items.length > 1"
        class="absolute-top-left z-top flex flex-center q-pa-md"
      >
        <div
          class="text-body2 text-weight-medium text-primary q-px-sm q-py-xs"
          style="backdrop-filter: blur(10px);"
        >
          {{ index + 1 }} / {{ items.length }}
        </div>
      </div>
      <div class="absolute-bottom z-top flex flex-center">
        <div
          class="text-caption text-center text-grey-9 text-weight-medium q-px-sm q-py-xs"
          style="backdrop-filter: blur(10px); line-height: 1.2;"
        >
          For illustration purposes only, this image may not be an exact
          representation of the product.
        </div>
      </div>
      <swiper-slide
        v-for="(item, i) in items"
        :key="i"
        :style="{ cursor: items.length > 1 ? 'grab' : 'default' }"
      >
        <q-img
          v-bind="item"
          ratio="1.7777"
          fit="contain"
        />
      </swiper-slide>
      <div
        v-if="items[index]!.src != '/placeholder.svg'"
        class="absolute-top-right q-pa-md"
      >
        <q-btn
          :icon="mdiFullscreen"
          color="primary"
          class="z-top"
          dense
          flat
          round
          @click="dialog({
            component: LazyDialogGallery,
            componentProps: {
              items: gallery,
              initial: index,
            },
          })"
        />
      </div>
    </swiper>
  </div>
</template>

<script lang="ts" setup>
import type SwiperClass from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import { mdiChevronLeft, mdiChevronRight, mdiFullscreen } from '@quasar/extras/mdi-v7';
import type { QImgProps } from 'quasar';
import { LazyDialogGallery } from '#components';

import 'swiper/scss';

const { product } = useProduct()!;
const { dialog } = useQuasar();

const carousel = ref<SwiperClass | null>(null);
const index = ref(0);

const items = computed<QImgProps[]>(() => ([
  {
    src: img(product.imageUrl),
    placeholderSrc: img(product.imageUrl),
    loading: 'eager',
    fetchpriority: 'high',
    spinnerColor: 'primary',
    noTransition: true,
  },
  ...product.assets.map(a => ({
    src: img(a.url),
    spinnerColor: 'primary',
  })),
]));

const gallery = computed<QImgProps[]>(() => {
  interface GalleryItem {
    src: string;
    caption?: string;
  }

  const stack: GalleryItem[] = [];

  if (product.imageUrl?.length) {
    stack.push({ src: product.imageUrl });
  }

  return [...stack, ...product.assets.map(a => ({ src: a.url }))];
});

const img = (url?: string | null) => {
  if (!url?.length) {
    return '/placeholder.svg';
  }

  return useCloudflareImage(url, { f: 'auto', w: 800, h: 800 });
};

onDeactivated(() => carousel.value?.autoplay.pause());

onActivated(() => carousel.value?.autoplay.start());
</script>
