<template>
  <div>
    <div v-if="visible">
      <div class="row items-center q-gutter-x-lg">
        <div
          v-if="price < regularPrice"
          class="self-stretch"
        >
          <div class="row flex-center bg-positive fit">
            <div class="text-h5 text-weight-medium q-px-sm text-green-2">
              {{ percent(((regularPrice - price) / regularPrice) * 100 * -1) }}
            </div>
          </div>
        </div>

        <div>
          <div class="column items-end">
            <div
              v-if="price < regularPrice"
              class="text-body1 text-weight-medium text-strike text-grey-6"
              style="line-height: normal"
            >
              {{ currency(regularPrice) }}
            </div>
            <div class="text-weight-medium price">
              {{ currency(price) }}
            </div>
          </div>
        </div>

        <q-separator
          v-if="product.unitPrice"
          vertical
        />

        <div v-if="product.unitPrice">
          <div class="column items-center">
            <div
              class="text-h6 text-weight-medium"
              style="line-height: 1"
            >
              {{ currency(product.unitPrice) }}
            </div>
            <div
              class="text-body2 text-weight-light"
              style="line-height: 1"
            >
              per
            </div>
            <div
              class="text-body2 text-weight-light"
              style="line-height: 1"
            >
              {{ product.isAmmunition ? 'round' : 'unit' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="row items-center q-gutter-md"
    >
      <q-skeleton
        type="text"
        animation="none"
        width="125px"
        class="placeholder"
        square
      />

      <q-btn
        :icon="mdiHelp"
        color="secondary"
        flat
        round
        @click="onClick"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mdiHelp } from '@quasar/extras/mdi-v7';

const { product, variation, quoted } = useProduct()!;
const { currency, percent } = useFormatting();
const visible = ref<boolean>(!product.minimumAdvertisedPricePolicy || quoted.value);
const { mapDialog } = useDialogs();

const price = computed(() => variation.value?.price ?? product.price);

const regularPrice = computed(
  () => variation.value?.regularPrice ?? product.regularPrice,
);

const onClick = () => {
  if (product.minimumAdvertisedPricePolicy == null) {
    return;
  }

  if (product.minimumAdvertisedPricePolicy == 'Click') {
    visible.value = true;
    return;
  }

  mapDialog(product.minimumAdvertisedPricePolicy);
};
</script>

<style lang="scss" scoped>
@use "sass:map";

.price {
  font-size: map.get($h5, 'size');
  // line-height: map.get($h5, 'line-height');
  line-height: normal;

  @media (min-width: $breakpoint-md-min) {
    font-size: map.get($h4, 'size');
    // line-height: map.get($h4, 'line-height');
    line-height: 1;
  }
}

.placeholder {
  font-size: map.get($h4, 'size');

  @media (min-width: $breakpoint-md-min) {
    font-size: map.get($h3, 'size');
  }
}

.line-height-normal {
  line-height: 1 !important;
}
</style>
