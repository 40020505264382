<template>
  <div>
    <div class="row justify-between items-center">
      <q-item
        v-if="product.isFreeShipping"
        class="col-12 col-sm-auto"
      >
        <q-item-section avatar>
          <q-avatar
            :icon="mdiTruckCheckOutline"
            size="xl"
            color="positive"
            text-color="white"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h6 text-weight-medium">
            Free Shipping!
          </q-item-label>
          <q-item-label class="text-body2">
            Lower 48 States only.
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        v-if="product.isQualifiedProfessional"
        to="/page/qualified-professionals"
        class="col-12 col-sm-auto"
      >
        <q-item-section avatar>
          <q-avatar
            :icon="mdiPoliceBadgeOutline"
            size="xl"
            color="blue-10"
            text-color="white"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h6 text-weight-medium">
            Qualified Professionals Only
          </q-item-label>
          <q-item-label class="text-body2">
            Learn more about KYGUNCO's Qualified Professional program.
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-icon
            :name="mdiInformationOutline"
            size="md"
            color="grey"
          />
        </q-item-section>
      </q-item>
      <q-item class="col-12 col-sm-auto">
        <q-item-section avatar>
          <q-avatar
            :icon="product.isFirearm ? mdiStoreOutline : mdiHomeOutline"
            size="xl"
            color="white"
            text-color="primary"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h6 text-weight-medium">
            <span v-if="product.isFirearm">Ships To FFL Dealer</span>
            <span v-else>Ships To Your Home</span>
          </q-item-label>
          <q-item-label class="text-body2">
            <span v-if="product.isDropshipable && product.vendor != 'KyGunCo'">
              Fulfilled by a partner warehouse.
            </span>
            <span v-else>
              Handled by our expert shippers in Bardstown, KY.
            </span>
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        v-if="product.vendor != 'KyGunCo'"
        class="col-12 col-sm-auto"
      >
        <q-item-section avatar>
          <q-avatar
            :icon="mdiHandshakeOutline"
            size="xl"
            color="white"
            text-color="primary"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h6 text-weight-medium">
            Partner Fulfilled
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        v-if="product.orderType == 'Backorder' && !product.quantity"
        class="col-12 col-sm-auto"
      >
        <q-item-section avatar>
          <q-avatar
            :icon="mdiTruckFastOutline"
            size="xl"
            color="primary"
            text-color="white"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h6 text-weight-medium">
            Backordered
          </q-item-label>
          <q-item-label class="text-body2">
            This item has sold out due to high demand but is expected to be back in stock soon.
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        v-if="product.orderType == 'Preorder'"
        class="col-12 col-sm-auto"
      >
        <q-item-section avatar>
          <q-avatar
            :icon="mdiCalendarStar"
            size="xl"
            color="primary"
            text-color="white"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h6 text-weight-medium">
            Preorder
          </q-item-label>
          <q-item-label class="text-body2">
            This item can be ordered before it is officially
            released and available for general sale.
          </q-item-label>
        </q-item-section>
      </q-item>
    </div>

    <LazyProductRebates
      v-if="product.rebateCount"
      :id="product.id"
    />

    <ProductAction
      v-if="!dimensions.length || !!variation"
      class="q-mt-md"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  mdiHandshakeOutline,
  mdiHomeOutline,
  mdiTruckCheckOutline,
  mdiTruckFastOutline,
  mdiStoreOutline,
  mdiCalendarStar,
  mdiPoliceBadgeOutline,
  mdiInformationOutline,
} from '@quasar/extras/mdi-v7';

const { product, variation, dimensions } = useProduct()!;
</script>
